<template>
  <div class="d-flex justify-space-between align-baseline">
    <v-switch
      inset
      :input-value="value"
      @change="$emit('change', $event)"
      dense
      class="ms-3 mb-4 login-logout-checkbox"
      :true-value="true"
      :false-value="false"
      :disabled="disabled"
    >
      <template v-slot:label>
        <div class="d-flex flex-column">
          <div
            :class="`${
              value
                ? 'green--text text--lighten-2'
                : 'red--text text--lighten-2'
            } text-uppercase text-body-1 font-weight-medium`"
          >
            {{ value ? $ml.get("active") : $ml.get("disabled") }}
          </div>
          <div
            style="white-space: normal"
            class="font-weight-regular text-body-2"
          >
            {{
              value
                ? $ml.get(disable_description_key)
                : $ml.get(activate_description_key)
            }}
          </div>
        </div>
      </template>
    </v-switch>
    <div>
      <v-chip
        v-show="recommended"
        small
        color="primary"
        class="font-weight-medium"
        :disabled="disabled"
        >{{ $ml.get("recommended") }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveDisabledSwitch",
  props: {
    value: { type: Boolean, default: () => false },
    disable_description_key: { type: String, default: () => "" },
    activate_description_key: { type: String, default: () => "" },
    disabled: { type: Boolean, default: () => false },
    recommended: { type: Boolean, default: () => false },
  },
};
</script>
